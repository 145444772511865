@use '../settings/' as *;

a:not([class]) {
  color: $color-text;
  outline: 0;
  text-decoration: none;

  &:focus,
  &:hover {
    text-decoration: underline;
  }
}
