@use '../settings/' as *;

b,
strong {
  font-weight: $font-weight-bold;
}

i,
em {
  font-style: italic;
}
