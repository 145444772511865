@use '@/assets/sass/settings/' as *;
@use '@/assets/sass/tools/' as *;

.preview-alert {
  position: fixed;
  z-index: 9999999999;
  bottom: rem(20);
  left: rem(20);
  max-width: 360px;
  padding: rem(20);
  // border: 1px solid $color-green-dark;
  // background-color: $color-green-light;
  // color: $color-blue-dark;
  font-family: monospace;
  font-size: rem(20);
  line-height: 1.25;
}
