@use '../settings/' as *;
@use '../tools/' as *;

.vv-section {
  padding-top: $spacing-20;
  padding-bottom: $spacing-20;

  @include respond-from(medium) {
    padding-top: $spacing-40;
    padding-bottom: $spacing-40;
  }

  & + & {
    padding-top: 0;
  }
}
