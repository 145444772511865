// Reduced Motion and Slow refresh rates
// Source: https://css-tricks.com/revisiting-prefers-reduced-motion-the-reduced-motion-media-query/
@media screen and
  (prefers-reduced-motion: reduce),
  (update: slow) {
  *,
  *::before,
  *::after {
    animation-duration: 0.001ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.001ms !important;
  }
}
