@use '../settings/' as *;
@use '../tools/' as *;

.vv-layout {
  max-width: 100%;
  padding: 0 $spacing-20;
  margin: 0 auto;

  .vv-layout {
    padding: 0;
  }

  @include respond-from(large) {
    padding: 0 $spacing-40;
  }

  @each $breakpoint, $size-info in $breakpoints {
    @if (map-get($size-info, 'container')) {
      @include respond-from($breakpoint) {
        max-width: map-get($size-info, 'container');
        padding: 0;
      }
    }
  }
}
