@use '../settings/' as *;
@use '../tools/' as *;

ul:not([class]),
ol:not([class]) {
  padding-left: rem(10);
  margin: 0;
  list-style: none;
}

ol:not([class]) {
  counter-reset: counter;
}

li:not([class]) {
  position: relative;
  padding: 0;
  margin: 0;
  counter-increment: counter;

  &::before {
    position: absolute;
    top: 0;
    left: rem(-10);

    ul:not([class]) & {
      content: '\2022';
      font-weight: $font-weight-bold;
    }

    ol:not([class]) & {
      content: counter(counter);
    }

    ul:not([class]) ul:not([class]) & {
      content: '\25CB';
      font-size: rem(8);
      line-height: rem(18);
    }
  }
}
